<template>
  <ModalAlert
    :show="props.show && enableConfirmModal !== false"
    title="セット購入確認"
    size="xl"
    :is-enable-ok="false"
    :is-enable-cancel="false"
    :close-button="isLimitBuy"
    @close="onCancel"
    @click-ok="onBuyProcess"
    @click-cancel="onCancel"
  >
    <div class="-my-5 pt-5 px-3 text-center flex flex-col w-full h-full">
      <div class="flex flex-col grow w-full h-full">
        <CommonUserGem :diamond-shop="true" class="pb-4" @on-add-point="onCancel" />
        <div class="grow overflow-y-auto -mx-3 px-3">
          <div class="mb-3">
            <div class="attention-text mb-0.5">
              このセットを購入しますか？
            </div>
            <div v-if="canBuyTotalCount > 0" class="limit-text text-xs">
              残り{{ canBuyTotalCount }}個まで購入できます
            </div>
            <div v-else class="limit-text text-xs">
              購入上限数に達しているため購入できません
            </div>
          </div>
          <div class="combinedsale-item-info mb-4">
            <ModalInnerInfo :scroll="false" class="w-full" align="left">
              <div class="flex flex-col w-full">
                <div class="flex flex-row items-center">
                  <div class="grow-0 line-h-0">
                    <ResourceImg
                      v-if="props.shopCombinedsaleItem"
                      asset="item"
                      :partition="props.shopCombinedsaleItem.resourcePartition ?? 0"
                      :cd="props.shopCombinedsaleItem.resourceItemCd ?? ''"
                      class="item-image"
                    />
                  </div>
                  <div class="item-title text-lg grow px-4 text-left">
                    <p>{{ props.shopCombinedsaleItem.itemName }}</p>
                  </div>
                </div>
                <div class="set-title text-center p-2">
                  ▼セット内容▼
                </div>
                <div class="flex flex-col grow w-full p-2 mt-2">
                  <div v-if="props.shopCombinedsaleItem.specialStoryList.length > 0">
                    <div>
                      【ストーリー】
                    </div>
                    <div v-for="(v, k) in props.shopCombinedsaleItem.specialStoryList" :key="k">
                      ・{{ v.description }}
                    </div>
                  </div>
                  <div v-if="presentCardList.length > 0">
                    <div>
                      【カード】
                    </div>
                    <div v-for="(v, k) in presentCardList" :key="k">
                      ・{{ v.description }}
                    </div>
                  </div>
                  <div v-if="props.shopCombinedsaleItem.stillList.length > 0">
                    <div>
                      【スチル】
                    </div>
                    <div v-for="(v, k) in props.shopCombinedsaleItem.stillList" :key="k">
                      ・{{ v.description }}
                    </div>
                  </div>
                  <div v-if="presentAvatarList.length > 0">
                    <div>
                      【{{ CommonNaming.avatar }}】
                    </div>
                    <div v-for="(v, k) in presentAvatarList" :key="k">
                      ・{{ v.description }}
                    </div>
                  </div>
                  <div v-if="presentItemList.length > 0">
                    <div>
                      【アイテム】
                    </div>
                    <div v-for="(v, k) in presentItemList" :key="k">
                      ・{{ v.description }}×{{ v.itemCount }}
                    </div>
                  </div>
                </div>
              </div>
            </ModalInnerInfo>
          </div>
          <template v-if="canBuyTotalCount > 0">
            <CommonButton v-if="props.buyMaxCount > 1" class="w-full" type="info" @click="onBuyCountEdit">
              ▼購入数: {{ buyCount }}
            </CommonButton>
            <!-- 費用情報表示 -->
            <ModalShopCostInfo :cost="props.cost" class="w-full" />
          </template>
        </div>
        <div class="flex flex-col justify-center modal-buttons mt-2">
          <div v-if="presentCardList.length > 0" class="limit-text text-xs justify-center">
            既に所持しているカードがある場合は<br>
            {{ CommonNaming.presentBox }}にて所持しているカードを受け取ると<br>
            自動合成されます
          </div>
          <div class="text-center">
            <SpecifiedCommercialButton />
          </div>
          <div v-if="!isLimitBuy" class="flex justify-center">
            <CommonButton type="secondary" class="modal-btn flex flex-col items-center justify-center" @click="onCancel">
              <div>
                キャンセル
              </div>
            </CommonButton>
            <ModalShopBuyButton @buy="onBuyProcess" @cancel="onCancel" />
          </div>
        </div>
      </div>
    </div>
  </ModalAlert>
</template>

<script lang="ts" setup>
import {
  UserItem,
  ShopItemBuyType, UserShopLimitItem, ShopCombinedsaleItem,
  ShopCombinedsaleItemPresent, UserShopCombinedsaleItem,
} from 'api';
import { getBuyMaxCountShopCombinedsaleItem, isCanBuyCost, isCanBuyShopCombinedsaleItem } from '~/libs/page/shop/type';
import { ShopBuyCost, useWallet } from '~/composables/useWallet';
import { CommonNaming } from '~/configs/common';

const logGroup = 'modal/shop/buyCombinedsaleItem';
interface Props {
  show: boolean,
  userItem?: UserItem,
  userShopLimitItem?: UserShopLimitItem,
  shopCombinedsaleItem: ShopCombinedsaleItem,
  userShopCombinedsaleItem: UserShopCombinedsaleItem,
  buyClosetFlg?: boolean,
  buyCount: number,
  buyMaxCount: number,
  buyType: ShopItemBuyType,
  cost: ShopBuyCost,
}
const props = withDefaults(defineProps<Props>(), {
  userItem: undefined,
  userShopLimitItem: undefined,
  buyClosetFlg: false,
  buyCount: 1,
  buyMaxCount: 1,
});
interface Emits {
  (e: 'onBuy', shopCombinedsaleItem: ShopCombinedsaleItem): void;
  (e: 'onChangeSelectCount', shopCombinedsaleItem: ShopCombinedsaleItem): void;
  (e: 'onCancel', shopCombinedsaleItem: ShopCombinedsaleItem): void;
}
const emit = defineEmits<Emits>();

const presentCardList = ref<ShopCombinedsaleItemPresent[]>([]);
const presentAvatarList = ref<ShopCombinedsaleItemPresent[]>([]);
const presentItemList = ref<ShopCombinedsaleItemPresent[]>([]);
const isCanBuy = ref<boolean>(false);
const isLimitBuy = ref<boolean>(false);
const canBuyCostEnable = ref<boolean>(false);
const canBuyTotalCount = ref<number>(0);
const enableConfirmModal = ref<boolean>(true);
/**
 * 初期化処理
 */
const init = (data: Props) => {
  presentCardList.value = [];
  presentAvatarList.value = [];
  presentItemList.value = [];
  data.shopCombinedsaleItem.presentList.forEach((it: ShopCombinedsaleItemPresent) => {
    if (it.type === 'card') {
      presentCardList.value.push(it);
    } else if (it.type === 'avatar') {
      presentAvatarList.value.push(it);
    } else if (it.type === 'item') {
      presentItemList.value.push(it);
    } else {
      useLog(logGroup).warn(`Not found presentType ${it.type}`);
    }
  });
  canBuyTotalCount.value = props.userShopLimitItem ? getLessBuyCount(props.shopCombinedsaleItem, props.userShopLimitItem) : 0;
  canBuyCostEnable.value = isCanBuyCost({
    cost: data.cost,
    wallet: useWallet().wallet.value,
    animateWallet: useAnimateWallet().wallet.value,
  });
  isCanBuy.value = canBuyCostEnable.value && isCanBuyShopCombinedsaleItem(data.userShopCombinedsaleItem, useLabotan().date, data.userShopLimitItem);
  isLimitBuy.value = canBuyTotalCount.value <= 0;
  enableConfirmModal.value = data.show ? true : enableConfirmModal.value;
};

/**
 * Android対応Backキー対応
 */
defineAppBack(() => {
  // 購入画面が表示されている状態なら閉じる
  if (enableConfirmModal.value) {
    enableConfirmModal.value = false;
  }
  // 元の処理を実行
  return false;
});

onMounted(() => {
  init(props);
});
watch(props, data => init(data));
/**
 * 残り購入可能数
 */
const getLessBuyCount = (shopCombinedsaleItem: ShopCombinedsaleItem, userShopLimitItem?: UserShopLimitItem) => {
  return getBuyMaxCountShopCombinedsaleItem(shopCombinedsaleItem, userShopLimitItem, true);
};

/**
 * 購入数変更
 */
const onBuyCountEdit = () => {
  emit('onChangeSelectCount', props.shopCombinedsaleItem);
};
/**
 * 商品購入処理実行
 */
const onBuyProcess = () => {
  emit('onBuy', props.shopCombinedsaleItem);
};
/**
 * 商品購入キャンセル
 */
const onCancel = () => {
  enableConfirmModal.value = false;
  emit('onCancel', props.shopCombinedsaleItem);
};
</script>

<style lang="scss" scoped>
.attention-text {
  font-size: 1.1em;
  color: #282d44;
}

.limit-text {
  color: #e0004b;
}

.item-title {
  color: #bb9962;
}

.combinedsale-item-info {
  color: #282d44;
}

.item-image {
  width: 68px;
}

.modal-btn {
  min-width: 124px;
  margin-left: 0.5em;
  margin-right: 0.5em;
}

.buy-btn {
  font-size: 0.8rem;
}

/**
 * セット内容
 */
.set-title {
  border-bottom: solid 1px #282d44;
}
</style>
