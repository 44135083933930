<template>
  <ModalAlert
    :show="props.show"
    title="購入完了"
    size="lg"
    :is-enable-ok="false"
    :close-button="true"
    @close="onClose"
  >
    <div class="flex flex-col w-full h-full text-center items-center justify-center overflow-y-auto">
      <div class="w-full mb-4 text-color">
        <div>
          アイテムセットの購入が完了しました
        </div>
        <div v-if="props.shopCombinedsaleItem.presentList.length > 0">
          購入品は{{ CommonNaming.presentBox }}からお受け取りいただけます
        </div>
        <div v-if="props.shopCombinedsaleItem.specialStoryList.length > 0">
          ストーリーは彼コレ内「保存ストーリー」に贈られました
        </div>
        <div v-if="props.shopCombinedsaleItem.stillList.length > 0">
          スチルは彼コレ内「スチル」に贈られました。
        </div>
      </div>
      <div class="p-3 w-full">
        <!-- 費用情報表示 -->
        <ModalShopCostInfo :cost="props.cost" :message="false" class="w-full" />
      </div>
    </div>
  </ModalAlert>
</template>

<script lang="ts" setup>
import {
  UserItem,
  ShopItemBuyResponseData, ShopCombinedsaleItem,
} from 'api';
import { ShopBuyCost } from '~/composables/useWallet';
import { CommonNaming } from '~/configs/common';

interface Props {
  show: boolean,
  userItem?: UserItem,
  shopCombinedsaleItem: ShopCombinedsaleItem,
  buyResponse?: ShopItemBuyResponseData,
  buyCount: number,
  cost: ShopBuyCost,
}
const props = withDefaults(defineProps<Props>(), {
  userItem: undefined,
  buyResponse: undefined,
});
interface Emits {
  (e: 'onClose', shopCombinedsaleItem: ShopCombinedsaleItem): void;
}
const emit = defineEmits<Emits>();
/**
 * 完了
 */
const onClose = () => {
  emit('onClose', props.shopCombinedsaleItem);
};
</script>

<style lang="scss" scoped>
.text-color {
  color: #282d44;
}
</style>
