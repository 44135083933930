<template>
  <div>
    <!-- 購入確認表示 -->
    <ModalShopBuyCombinedsaleItemConfirm
      :show="props.show && enableConfirmModal !== false"
      :user-item="props.userItem"
      :user-shop-limit-item="props.userShopLimitItem"
      :shop-combinedsale-item="props.shopCombinedsaleItem"
      :user-shop-combinedsale-item="props.userShopCombinedsaleItem"
      :buy-closet-flg="props.buyClosetFlg"
      :buy-count="buyCount"
      :buy-max-count="buyMaxCount"
      :buy-type="buyType"
      :cost="buyCost"
      @on-buy="onBuyProcess"
      @on-change-select-count="onBuyCountEdit"
      @on-cancel="onCancel"
    />
    <!-- 購入結果表示 -->
    <ModalShopBuyCombinedsaleItemResult
      :show="props.show && enableResultModalData !== undefined"
      :user-item="props.userItem"
      :shop-combinedsale-item="props.shopCombinedsaleItem"
      :buy-response="enableResultModalData"
      :buy-count="buyCount"
      :cost="buyCost"
      @on-close="onBuyComplete"
    />
    <!-- 購入数変更 -->
    <ModalSelect
      :show="props.show && enableBuyCountEditModal"
      title="選択"
      :select-item-list="selectBuyCountList"
      :select-item="buyCount.toString()"
      size="lg"
      @close="onBuyCountComplete"
      @select-item="onBuyCountSelect"
    />
    <!-- ダイヤ不足(ダイヤ購入確認) -->
    <LazyModalShopBuyDiaConfirm
      :show="props.show && enableNotDiaErrorModal"
      :gem="props.shopCombinedsaleItem.gemPrice * buyCount"
      :purchased-only-flg="props.shopCombinedsaleItem.purchasedOnlyFlg"
      @on-ok="onBuyErrorComplete"
      @on-cancel="onBuyErrorCompleteCancel"
    />
    <!-- 購入エラー -->
    <LazyModalShopBuyError
      :show="props.show && enableErrorModal"
      :message="enableErrorModalMessage"
      @on-ok="onBuyErrorComplete"
    />
  </div>
</template>

<script lang="ts" setup>
import {
  UserItem,
  ShopItemBuyResponseData,
  ShopItemBuyType, UserShopLimitItem, ShopCombinedsaleItem, UserShopCombinedsaleItem,
} from 'api';
import { getBuyMaxCountShopCombinedsaleItem, isCanBuyCost } from '~/libs/page/shop/type';
import { getShopBuyCostShopCombinedsaleItem, ShopBuyCost } from '~/composables/useWallet';
import { ApiError } from '~/libs/errors';
import { errorConfig } from '~/configs/errors';

interface Props {
  show: boolean,
  userItem?: UserItem,
  userShopLimitItem?: UserShopLimitItem,
  shopCombinedsaleItem: ShopCombinedsaleItem,
  userShopCombinedsaleItem: UserShopCombinedsaleItem,
  buyClosetFlg?: boolean,
}
const props = withDefaults(defineProps<Props>(), {
  userItem: undefined,
  userShopLimitItem: undefined,
  buyClosetFlg: false,
});
interface Emits {
  (e: 'onSuccess', shopCombinedsaleItem: ShopCombinedsaleItem): void;
  (e: 'onCancel', shopCombinedsaleItem: ShopCombinedsaleItem): void;
}
const emit = defineEmits<Emits>();

const buyType = ref<ShopItemBuyType>('gem');
const enableConfirmModal = ref<boolean>(true);
const enableResultModalData = ref<ShopItemBuyResponseData>();

const buyCount = ref<number>(1);
const buyMaxCount = ref<number>(1);
const buyCost = ref<ShopBuyCost>(getShopBuyCostShopCombinedsaleItem(props.shopCombinedsaleItem, buyCount.value));
/**
 * 初期化処理
 */
const init = (data: Props) => {
  buyCount.value = 1;
  enableConfirmModal.value = data.show ? true : enableConfirmModal.value;
  // 購入上限を取得
  buyMaxCount.value = getBuyMaxCountShopCombinedsaleItem(props.shopCombinedsaleItem, props.userShopLimitItem, false);
  // 費用を取得
  buyCost.value = getShopBuyCostShopCombinedsaleItem(props.shopCombinedsaleItem, buyCount.value);
};
onMounted(() => {
  init(props);
});
watch(props, data => init(data));
watch(buyCount, () => {
  // 費用を取得
  buyCost.value = getShopBuyCostShopCombinedsaleItem(props.shopCombinedsaleItem, buyCount.value);
});

type SelectItem = {
  key: string
  value: string
};
const enableBuyCountEditModal = ref<boolean>(false);
const selectBuyCountList = ref<SelectItem[]>([]);
// ダイヤ不足
const enableNotDiaErrorModal = ref<boolean>(false);
// エラー
const enableErrorModal = ref<boolean>(false);
const enableErrorModalMessage = ref<string>('');
/**
 * 購入数変更
 */
const onBuyCountEdit = () => {
  // 購入上限を取得
  if (buyMaxCount.value < 1) {
    return;
  }
  selectBuyCountList.value = Array.from(Array(buyMaxCount.value).keys()).map((i) => {
    const n = (i + 1).toString();
    return {
      key: n,
      value: n,
    };
  });
  enableConfirmModal.value = false;
  enableBuyCountEditModal.value = true;
};
/**
 * 購入数変更完了
 */
const onBuyCountComplete = () => {
  enableConfirmModal.value = true;
  enableBuyCountEditModal.value = false;
};
/**
 * 購入数変更選択
 */
const onBuyCountSelect = (selectItem: string) => {
  buyCount.value = Number(selectItem);
  enableConfirmModal.value = true;
  enableBuyCountEditModal.value = false;
};
/**
 * 商品購入処理実行
 */
const onBuyProcess = async () => {
  if (!isCanBuyCost({
    cost: buyCost.value,
    wallet: useWallet().wallet.value,
    animateWallet: useAnimateWallet().wallet.value,
  })) {
    // ダイヤ購入不足
    enableNotDiaErrorModal.value = true;
    return;
  }
  const key = useLoading().loader.start({ blocked: true, });
  enableConfirmModal.value = false;
  try {
    const buyResponse = await useApi().user.postShopItemBuy({
      shopItemBuyRequest: {
        shopItemCd: props.shopCombinedsaleItem.shopItemCd,
        buyCount: buyCount.value,
        buyType: buyType.value,
        freeGem: buyCost.value.freeGem,
        purchasedGem: buyCost.value.purchasedGem,
        gesoCoin: buyCost.value.gesoCoin,
        gold: buyCost.value.gold,
      },
    });
    enableResultModalData.value = buyResponse.data;
  } catch (e) {
    if (e instanceof ApiError) {
      if (e.code === errorConfig.errorCodes.notGoldOrPointError) {
        // ダイヤ購入不足
        enableNotDiaErrorModal.value = true;
        return;
      } else if (e.code === errorConfig.errorCodes.notCanBuyShopItemError) {
        // 期間外エラー
        enableErrorModal.value = true;
        enableErrorModalMessage.value = errorConfig.messages.notCanBuyShopItemError;
        return;
      } else if (e.code === errorConfig.errorCodes.userItemCountMaxError) {
        // エラー
        enableErrorModal.value = true;
        enableErrorModalMessage.value = errorConfig.messages.userItemCountMaxError;
        return;
      }
    }
    emit('onCancel', props.shopCombinedsaleItem);
    throw e;
  } finally {
    useLoading().loader.stop(key);
  }
};
/**
 * 商品購入キャンセル
 */
const onCancel = () => {
  enableConfirmModal.value = false;
  emit('onCancel', props.shopCombinedsaleItem);
};
/**
 * 商品購入結果完了
 */
const onBuyComplete = () => {
  enableResultModalData.value = undefined;
  emit('onSuccess', props.shopCombinedsaleItem);
};
/**
 * エラー表示終了
 */
const onBuyErrorComplete = () => {
  enableNotDiaErrorModal.value = false;
  enableErrorModal.value = false;
  emit('onCancel', props.shopCombinedsaleItem);
};
const onBuyErrorCompleteCancel = () => {
  if (enableConfirmModal.value) {
    enableNotDiaErrorModal.value = false;
  } else {
    onBuyErrorComplete();
  }
};
</script>

<style lang="scss" scoped>
.item-title {
  color: #947f2f;
}

.item-image {
  width: 68px;
  height: 68px;
}

.buy-btn {
  font-size: 0.8rem;
  min-width: 114px;
}
</style>
